<template>
  <OForm @submit="submit" :class="{submitting}">
    <OFormInput
      id="message"
      name="message"
      type="textarea"
      placeholder="How can we help you…"
      v-model="form.message"
      required
    >
      We want to hear from you:
    </OFormInput>
    <OFormInput
      id="email"
      name="email"
      type="email"
      placeholder="Enter your email"
      v-model="form.email"
      required
    >
      Your email (so we can reply):
    </OFormInput>
    <OFormError :message="error"></OFormError>
    <FormButtons>
      <OFormSubmitButton :disabled="submitting">
        Send
      </OFormSubmitButton>
    </FormButtons>
    <p class="info">Alternatively, you could write to us at
      <o-link
        class="c-form-submit__email"
        href="mailto:info@myphonerobot.com">info@myphonerobot.com
      </o-link>
    </p>
  </OForm>
</template>

<script>
import apiForm from '../mixins/apiForm'
import OForm from '../objects/forms/OForm'
import OFormInput from '../objects/forms/OFormInput'
import OFormSubmitButton from '../objects/forms/OFormSubmitButton'
import OLink from '../objects/OLink'
import FormButtons from '../objects/forms/FormButtons'
import OFormError from '../objects/forms/OFormError'

export default {
  name: 'CFormFeedbackForm',
  components: { OFormError, OLink, OFormSubmitButton, OFormInput, OForm, FormButtons },
  mixins: [apiForm],
  data: function () {
    return {
      form: {
        message: '',
        email: '',
        user: this.$store.getters.getEmail
      }
    }
  },
  methods: {
    async submit () {
      const token = await this.$recaptcha('feedback')
      this.form.token = token

      this.$callApi(api => api.feedback(this.form))
    }
  }
}
</script>

<style lang="scss">
  .c-form-submit__email {
    text-transform: lowercase;
  }
</style>
